<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-12">
      <div class="card full-height bg-ligth">
        <div class="card-header"></div>

        <div class="card-body">
          <b-table
            :fields="[
              'id',
              'forma_pagamento',         
              'valor',
              'cambio',
              'acoes',
            ]"
            :items="lista_finan_contas_receber.contas_receber"
            :per-page="perPage"
            :current-page="currentPage"
            id="contas_receber-table"
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
            show-empty
            empty-text="Nenhum registro encontrado!"
          >
            <template #head(acoes)><span></span></template>
            <template #head(finan_pedido_recebimento_id)>
              <div>N. Pedido</div>
            </template>

    
            <template #cell(valor)="{ item }">
              {{ item.valor | formatMoneyPY }}
            </template>
            <template #cell(acoes)="{ item }">
              <button
                v-if="item.baixa === null"
                v-show="lista_permissoes_filial.lock_FinanCambio"
                @click="abrirModalFinan(item)"
                class="btn btn-icon btn-light btn-sm mx-1 text-warning"
                v-b-tooltip.hover
                title="Dar Baixa"
              >
                <i class="far fa-list-alt text-warning"></i>
              </button>

              <!-- <button
                v-if="item.baixa !== null"
                @click="verComprovante(item)"
                class="btn btn-icon btn-light btn-sm mx-1 disabled"
              >
                <i class="far fa-list-alt text-info"></i>
              </button> -->
              <button
                v-if="item.baixa === null"
                v-show="lista_permissoes_filial.u_FinanPedidoRecebimento"
                @click="atualizar(item)"
                class="btn btn-icon btn-light btn-sm mx-1"
                v-b-tooltip.hover
                title="Editar registro"
              >
                <i class="far fa-edit text-primary"></i>
              </button>
              <span v-b-tooltip="`Recebido ${item.baixa}`">
                <button
                  v-if="item.baixa !== null"
                  v-show="lista_permissoes_filial.lock_FinanCambio"
                  class="btn btn-icon btn-light btn-sm mx-1 disabled"
                >
                  <i class="far fa-list-alt text-success"></i>
                </button>
              </span>
            </template>
          </b-table>
          <b-pagination
            v-model="currentPage"
            :total-rows="lista_finan_contas_receber.contas_receber.length"
            :per-page="perPage"
            aria-controls="contas_receber-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>

    <b-modal
      v-model="modal_parcela.show"
      title="Financeiro"
      centered
      hide-footer
    >
      <b-overlay :show="modal_parcela.loading" rounded="sm">
        <form>
          <div class="form-group row">
            <div class="col-md-12">
              <label for="contrato">Valor: G$</label>
              <input
                id="valor"
                type="text"
                class="form-control"
                v-model="modal_parcela.form.valor"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12">
              <label for="parcelas">Moeda:*</label>
              <select
                id="parcelas"
                class="form-control"
                v-model="modal_parcela.form.moeda_id"
              >
                <option
                  v-for="item in lista_finan_moedas"
                  :key="item"
                  :value="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12 text-right">
              <button @click.prevent="submitPedido()" class="btn btn-primary">
                Salvar
              </button>
            </div>
          </div>
        </form>
      </b-overlay>
    </b-modal>

    <b-modal
      v-model="modal_finan.show"
      title="Baica do Titulo"
      centered
      hide-footer
    >
      <b-overlay :show="modal_finan.loading" rounded="sm">
        <form>
          <div class="form-group row">
            <div class="col-md-12">
              <label for="parcelas">Modo Pagamento:*</label>
              <select
                id="parcelas"
                class="form-control"
                v-model="modal_finan.form.finan_modo_pagamento_id"
              >
                <option
                  v-for="item in lista_finan_forma_pagamentos"
                  :key="item"
                  :value="item.id"
                >
                  {{ item.nome }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12">
              <label for="parcelas">Conta Entrada:*</label>
              <select
                id="parcelas"
                class="form-control"
                v-model="modal_finan.form.finan_conta_id"
              >
                <option
                  v-for="item in lista_finan_contas"
                  :key="item"
                  :value="item.id"
                >
                  {{ item.banco }} Ag: {{ item.agencia }} Conta:
                  {{ item.conta }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-12">
              <label for="contrato">Descricao: </label>
              <input
                id="valor"
                type="text"
                class="form-control"
                v-model="modal_parcela.form.descricao"
              />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-12 text-right">
              <button @click.prevent="confirm()" class="btn btn-primary">
                Dar Baixa
              </button>
            </div>
          </div>
        </form>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import moneyFormat from "@/core/mixins/moneyFormat";
import dateFormat from "@/core/mixins/dateFormat";

export default {
  mixins: [fireAlert, moneyFormat, dateFormat],
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      modal_parcela: {
        show: false,
        loading: false,

        form: {
          valor: null,
          moeda_id: null,
          perdido_id: null,
        },
      },
      modal_finan: {
        show: false,
        loading: false,

        form: {
          finan_modo_pagamento_id: null,
          descricao: null,
          id_finan_conta_recebimento: null,
          finan_conta_id: null,
          baixa: null,
        },
      },
      valorParcela: null,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contas a Receber" }]);
  },
  computed: {
    lista_finan_contas() {
      return this.$store.state.financeiro.lista_finan_contas;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    lista_finan_contas_receber() {
      return this.$store.state.financeiro.lista_finan_contas_receber;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    lista_finan_moedas() {
      return this.$store.state.financeiro.lista_finan_moedas;
    },
    lista_finan_forma_pagamentos() {
      return this.$store.state.financeiro.lista_finan_forma_pagamentos;
    },
  },
  created() {
    this.listar_finan_contas_receber();
    this.listar_finan_moeda();
  },
  methods: {
    async listar_contas() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch("listar_finan_contas").finally(() => {
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    async abrirModalFinan(value) {
      this.modal_finan.show = true;
      this.modal_finan.form.id_finan_conta_recebimento = value.id;
      this.listar_finan_modo_pagamento();
      this.listar_contas();
    },
    async listar_finan_modo_pagamento() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("financeiro/listar_finan_modo_pagamento")
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          // this.$store.dispatch("atualizar", "");
        });
    },

    async submitPedido() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("financeiro/criarParcela", this.modal_parcela.form)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
        });
      this.listar_finan_contas_receber();

      this.modal_parcela.show = false;
    },
    NovaParcela() {
      let { pedido_id } = this.$route.query;
      this.modal_parcela.form.perdido_id = pedido_id;
      this.modal_parcela.show = true;
    },

    async listar_finan_moeda() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store
        .dispatch("financeiro/listar_finan_moeda")
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          // this.$store.dispatch("atualizar", "");
        });
    },
    async excluir(item) {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);

      await this.$store
        .dispatch("financeiro/lock_parcelas", item)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);

          this.listar_finan_contas_receber();
        });
      this.fireAlert(this.mensagem_alert);
    },
    async listar_finan_contas_receber() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      const { caixa_id } = this.$route.params;
      if (!caixa_id) {
        this.fireAlert({
          title: "Nenhum Caixa foi selecionado!",
          tipo: "error",
          routeName: "caixa",
          timer: 5000,
        });
        this.$store.dispatch("configEmpresa/MudarPreloader", false);
        return;
      }
      await this.$store
        .dispatch("financeiro/listar_finan_contas_receber_caixa", caixa_id)
        .finally(() => {
          this.$store.dispatch("configEmpresa/MudarPreloader", false);
          this.$store.dispatch("configEmpresa/atualizar", "");
        });
    },
    async confirm() {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação dara baixa nessa conta a receber?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.baixar_finan_contas_receber();
          }
        },
      });
    },

    verComprovante(item) {
      let { pedido_id } = this.$route.query;
      let a = {
        id: item.id,
        finan_pedido_recebimento_id: pedido_id,
      };
      // window.open('http://example.com', '_blank');
      this.$store.dispatch(
        "financeiro/ver_comprovante_entrada_conta_receber",
        a
      );
      //  this.$router.push({name: "comprovante"});

      let routeData = this.$router.resolve({ name: "comprovante" });
      window.open(routeData.href, "_blank");
    },

    async baixar_finan_contas_receber() {
      this.$store.dispatch("configEmpresa/MudarPreloader", true);
      await this.$store.dispatch(
        "baixar_finan_contas_receber",
        this.modal_finan.form
      );
      this.$store.dispatch("configEmpresa/MudarPreloader", false);
      this.fireAlert(this.mensagem_alert);
      this.modal_finan.show = false;
      this.listar_finan_contas_receber();
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>